.smallDeliveryBtnContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.smallDeliveryDateBtn {
  margin-right: 2px;
  margin-bottom: 2px;
  padding: 2px 5px;
  width: 24%;
}

.open-order-customer-detail-table {
  width: 100%;
}

// Mobile screens
@media (min-width: 481px) {
  .smallDeliveryBtnContainer {
    display: none;
  }
}
