/**
 * Styles for both the older customer order item report and
 * the new region-wise extended customer order item report
 */
.customer-order-report-container {
    .mainGroupTableDiv {
        margin-bottom: 20px;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }

    .headerRow {
        background-color: rgba(255, 255, 102, 1);
        padding: 5px;
    }

    .bodyRow {
        background-color: #fff;
        margin-bottom: 1px;
        padding: 5px;
        border-bottom: 1px dotted #ccc;

        // Only the extended report will have mouse hover
        // and expansion feature - we need to include this
        // class with along with the .bodyRow class for those
        // styles to work
        &.hasChildTable {
            cursor: pointer;
    
            &::before {
                content: '+';
                position: absolute;
            }
    
            &:hover {
                background-color: #eee;
            }
        }

        .childContainer {
            background-color: #fff;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
                rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            border: 1px solid #bbb;
            margin: 10px 0 10px 0;
            
            .row {
                padding: 5px;
                border-bottom: 1px dotted #ccc;
            }
        }
    }
}